.wrap {
    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 1em;

        legend {
            width: 100%;
            margin-top: 1em;
            margin-bottom: 16px;
            font-weight: bold;
        }

        input,
        select {
            height: 38px;
            box-sizing: border-box;
            margin-right: 8px;
        }
    }
}

.transferModal {
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: content-box;
    padding-right: 20px;
}

.transfer_card {
    width: 100%;
    min-width: 480px;
    max-width: 1028px;
    padding-bottom: 16px;
    margin-top: 16px;
    padding: 4px 8px;
    box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 4px;
    .header {
        font-size: 18px;
        margin-bottom: 8px;
        align-items: center;
        column-gap: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .timeWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .shedule {
        margin-top: 6px;
    }
}

.timeWrapForAll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 4px;
    background-color: rgb(255, 244, 229);
}

.cell_wrap {
    height: fill-available;

    .cell_label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.shedule_list {
    grid-column-start: 1;
    grid-column-end: none;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.linkManager{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    > .btn {
        width: 100%;
    }
}