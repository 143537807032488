.loader {
    color: #007bff;
    font-size: 11px;
    text-indent: -99999em;
    margin: 55px auto;
    position: relative;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader, .loader:before, .loader:after {
    border-radius: 50%;
}

.loader:before, .loader:after {
    position: absolute;
    content: '';
}

.loader:after {
    width: 5.2em;
    height: 10.2em;
    background: #fff;
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    -webkit-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
}

.loader:before {
    width: 5.2em;
    height: 10.2em;
    background: #fff;
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 5.1em 5.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
}

@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.formControl {
    display: block;
    width: -webkit-fill-available;
    width: -moz-available;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
}

.siteFieldWrap{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .siteFieldTitle{
        height: calc(1.6em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 16px;
        font-weight: 600;
        border: 1px solid #ced4da;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        display: flex;
        align-items: center;
        min-width: 90px;
    }
    .formControl{
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.spinnerBorder {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    &.spinnerBorderSm {
        width: 1rem;
        height: 1rem;
        border-width: .2em;
    }
}

@-webkit-keyframes spinner-border{
    to{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@keyframes spinner-border{
    to{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}

.worktimeField{
    position: relative;
    margin-bottom: 30px;
    > label{
        position: absolute;
        left: auto;
        right: -57px;
        top: -25px;
        bottom: auto;
        text-align: center;
        width: 100px;
    }
}

h3{
  margin-left: 15px;
}
.fileInput{
  border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
  }
.imgPreview{
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 500px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 5px solid gray;
  border-bottom: 5px solid gray;
  img{
    width: 100%;
    height: 100%;
    }
  }
.previewText{
  width: 100%;
  margin-top: 20px;
  }
.submitButton{
  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
  &:hover{
    background: #efefef;
    }
}
.centerText{
  text-align: center;
  width: 500px;
}
.otherPlatformWrap{
    position: relative;
    > button{
        position: absolute;
        right: 0;
        top: 22px;
        cursor: pointer;
        height: calc(100% - 22px);
    }
}