img {
    max-width: 100%;
    margin: 1em auto;
    box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.5);
    width: fit-content;

}

.main {
    display: flex;
    flex-direction: column;
    font-size: 1.4em;
    line-height: 40px;
    padding: 1em;
    text-align: left;

    .ps {
        font-size: 20px;
        color: #8b8b8b;
        font-style: italic;
    }
}