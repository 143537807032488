.templateModalWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 'auto';
    background: #fff;
    border: 2px solid #a0a0a0;
    width: 80%;

    .templateForm {
        display: flex;
        flex-direction: column;
        padding: 1em;
        row-gap: 8px;

        label {
            display: flex;
            flex-direction: column;
        }
    }
}

.table {
    padding: 1em;

    .tableItem {
        display: grid;
        grid-template-columns: 25% 50% 1fr 1fr 1fr;
        min-height: 80px;
        text-align: left;
        gap: 6px;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
        padding: 0.5em 1em;
        margin-bottom: 1em;
    }
}

.template_wrap {
    gap: 6px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: .5em !important;
}

.card {
    display: inline-block;
    overflow: hidden;
    position: relative;
    max-width: 32%;
    width: 100%;
    height: 100px;
    z-index: 1;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
    border-radius: 9px;
    position: relative;
    cursor: pointer;
    transition: .3s;
    text-decoration: none;
    &:hover {
        transform: translate(0px, -8px);
    }

    .title {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 2em;
        z-index: 5;

        span {
            &:first-child {
                font-weight: bold;
            }
        }
    }

    .bg {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #00000096;
        z-index: -1;
    }

    img {
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -2;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
    }
}