.container {
    position: relative;
  }
  
  .sticky {
    position: sticky;
    top: 50%;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    /* затемнение */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
  }
  
  /* Пример простого CSS-спиннера */
  .spinner {
    width: 64px;
    height: 64px;
    border: 8px solid #ffffff;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 8px;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  .text {
    color: #fff;
    font-size: 16px;
  }
  